import moment from "moment";
import * as Yup from "yup";

const FIELD_REQUIRED = "This field is required";

export const YupISO8601Date = (required?: boolean) =>
  (required ? Yup.string().required(FIELD_REQUIRED) : Yup.string()).test(
    "is-iso8601-date",
    "Date should be in YYYY-MM-DD format",
    (value) => moment(value, "YYYY-MM-DD", true).isValid()
  );
