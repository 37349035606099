import React from "react";

interface SubmissionDataFieldProps {
  field: React.ReactNode;
  data: React.ReactNode;
  dataStyle?: React.CSSProperties;
  accessibleLabel?: string;
  fieldId?: string;
}

const SubmissionDataField: React.FC<SubmissionDataFieldProps> = ({
  field,
  data,
  dataStyle,
  accessibleLabel,
  fieldId,
}) => {
  const labelID =
    fieldId !== undefined ? `submission-field-${fieldId}-label` : undefined;

  const usingSeparateAccessibleLabel = accessibleLabel !== undefined;

  return (
    <div>
      {usingSeparateAccessibleLabel && (
        <div className="sr-only" id={labelID}>
          {accessibleLabel}
        </div>
      )}
      <label
        className="mb-0"
        id={usingSeparateAccessibleLabel ? undefined : labelID}
        // Hide this label to screen readers if we're using a custom one
        aria-hidden={usingSeparateAccessibleLabel}
      >
        <small className="text-muted">{field}</small>
      </label>
      <div style={dataStyle} aria-labelledby={labelID}>
        {data}
      </div>
    </div>
  );
};

export default SubmissionDataField;
