import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import JoinForm from "../joinForm/JoinForm";

const JoinFormPage: React.FC = () => (
  <Container>
    <Row>
      <Col>
        <h1>Join Form</h1>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col>
        <JoinForm />
      </Col>
    </Row>
  </Container>
);

export default JoinFormPage;
