import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { Address, AddressType } from "../../../models";

import AddressForm from "./AddressForm";

type AddressOmitIsPrimary = Omit<Address, "isPrimary">;

const defaultAddressData: AddressOmitIsPrimary = {
  line1: "",
  line2: "",
  town: "",
  region: "",
  country: "",
  postcode: "",
  dateFrom: "",
  dateTo: "",
  addressType: AddressType.PERMANENT,
};

const DEFAULT_IS_CURRENT_ADDRESS = false;
const DEFAULT_IS_UK_ADDRESS = true;

const addressDataReducer = (
  currentAddressData: AddressOmitIsPrimary,
  partialAddressData: Partial<AddressOmitIsPrimary>
) => {
  return {
    ...currentAddressData,
    ...partialAddressData,
  };
};

interface AddressFormModalProps {
  show: boolean;
  setShow: (s: boolean) => void;
  editingAddress: Address | undefined;
  setEditingAddress: (a: Address | undefined) => void;
  onAddressSubmit: (a: Address) => void;
}

const AddressFormModal: React.FC<AddressFormModalProps> = ({
  show,
  setShow,
  editingAddress,
  setEditingAddress,
  onAddressSubmit,
}) => {
  const [addressData, dispatchAddressData] = React.useReducer(
    addressDataReducer,
    defaultAddressData
  );

  const [isCurrentAddress, setIsCurrentAddress] = React.useState(
    DEFAULT_IS_CURRENT_ADDRESS
  );
  const [isUKAddress, setIsUKAddress] = React.useState(DEFAULT_IS_UK_ADDRESS);

  // Update isCurrentAddress to be correct if we edit an address
  React.useEffect(() => {
    setIsCurrentAddress(
      editingAddress !== undefined
        ? editingAddress.dateTo === null
        : DEFAULT_IS_CURRENT_ADDRESS
    );
    setIsUKAddress(
      editingAddress !== undefined
        ? editingAddress.country === "United Kingdom"
        : DEFAULT_IS_UK_ADDRESS
    );
  }, [editingAddress]);

  const [isSaving, setIsSaving] = React.useState(false);

  const [validateAddressForm, setValidateAddressForm] =
    React.useState<() => void>();

  const handleHide = React.useCallback(() => {
    // Hide the modal
    setShow(false);
  }, [setShow]);

  const handleExited = () => {
    // Reset values visible in the modal
    setEditingAddress(undefined);

    setIsCurrentAddress(DEFAULT_IS_CURRENT_ADDRESS);
    setIsUKAddress(DEFAULT_IS_UK_ADDRESS);

    setIsSaving(false);
  };

  const handleAddressSubmit = () => {
    // Trigger inner form validation
    if (validateAddressForm !== undefined) {
      validateAddressForm();
    } else {
      // This should never happen, so suitable to use an alert
      alert("Error: could not validate inner address form");
    }
  };

  const handleValidAddressSubmitted = React.useCallback(() => {
    setIsSaving(true);

    // Pass new address object up, ensuring the isPrimary status is preserved
    //   if we're editing an address
    onAddressSubmit({
      ...addressData,
      isPrimary:
        editingAddress !== undefined
          ? editingAddress?.isPrimary === true
          : false,
    });

    // Close the dialog
    handleHide();
  }, [addressData, editingAddress, handleHide, onAddressSubmit]);

  const isEditing = editingAddress !== undefined;

  return (
    <Modal
      show={show}
      onHide={handleHide}
      onExited={handleExited}
      aria-labelledby="address-form-modal-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="address-form-modal-title">
          {isEditing ? "Edit address" : "Add new address"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddressForm
          isCurrentAddress={isCurrentAddress}
          setIsCurrentAddress={setIsCurrentAddress}
          isUKAddress={isUKAddress}
          setIsUKAddress={setIsUKAddress}
          dispatchAddressData={dispatchAddressData}
          editingAddress={editingAddress}
          onFormRender={React.useCallback(
            ({ submitForm }) => {
              setValidateAddressForm(() => submitForm);
            },
            [setValidateAddressForm]
          )}
          onSubmit={handleValidAddressSubmitted}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleHide} disabled={isSaving}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleAddressSubmit}
          disabled={isSaving}
        >
          {isSaving
            ? isEditing
              ? "Saving address"
              : "Adding address"
            : isEditing
            ? "Save address"
            : "Add address"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddressFormModal;
