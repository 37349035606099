import React from "react";
import DebugBox, { DebugBoxProps } from "./DebugBox";

interface DebugDataBoxProps extends DebugBoxProps {
  data: any;
}

const DebugDataBox: React.FC<DebugDataBoxProps> = ({
  data,
  ...debugBoxProps
}) => (
  <DebugBox {...debugBoxProps}>
    <pre style={{ margin: 0 }}>{JSON.stringify(data, null, 2)}</pre>
  </DebugBox>
);

export default DebugDataBox;
