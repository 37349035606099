import React from "react";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "./AppRoutes";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import "./App.scss";

const App: React.FC = () => (
  <BrowserRouter>
    <header>
      <Header />
    </header>
    <main>
      <AppRoutes />
    </main>
    <Footer />
  </BrowserRouter>
);

export default App;
