export enum AddressType {
  PERMANENT = "PERM",
  TEMPORARY = "TEMP",
}

export enum ICUStatus {
  STUDENT = "STUDENT",
  ASSOCIATE = "ASSOCIATE",
}

export interface Address {
  line1: string;
  line2: string | null;
  town: string;
  region: string | null;
  country: string;
  postcode: string | null;

  dateFrom: string;
  dateTo: string | null;

  addressType: AddressType;

  isPrimary: boolean;
}

export interface FirearmCertificate {
  certificateNumber: string;
  issuingForce: string;
  expiryDate: string;
}

export interface JoinData {
  // Identity
  forenames: string;
  surname: string;
  preferredName: string | null;
  dateOfBirth: string;
  townOfBirth: string;
  countryOfBirth: string;
  nationality: string;

  // Contact details
  emailAddress: string;
  contactNumber: string;

  // College info
  cid: string;
  icuStatus: ICUStatus;
  collegeLogin: string | null;

  // Address history
  addresses: Address[];

  // FAC/SGC
  certificateRefusal: string | null;
  firearmCertificate: FirearmCertificate | null;
  shotgunCertificate: FirearmCertificate | null;

  // Health
  medicalConditions: string | null;
}
