import React from "react";

const IntroductionStage: React.FC = () => (
  <>
    <p className="lead">
      As a Home Office Approved Target Shooting Club, we have a legal obligation
      to collect certain information about all of our members. This information
      is essential for processing your application, and we cannot accept you as
      a member unless you provide it.
    </p>
    <p>
      Your data is held securely and must be retained by the Club for a period
      of seven years starting on the date you last attended the club, at which
      point all identifiable personal data will be deleted.
    </p>
    <p>
      Please also know, that by applying for membership, you consent to Imperial
      College &amp; St. Mary's Rifle &amp; Pistol Club disclosing the
      information you provide to the Metropolitan Police Service, as is required
      by law, who will conduct a criminal background check and advise the Club
      if you are a suitable person to be given access to firearms.
    </p>
  </>
);

export default IntroductionStage;
