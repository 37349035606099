import React from "react";

import Form from "react-bootstrap/Form";

import { JoinFormStageProps } from "../JoinForm";

import ConditionalExplanationField from "../../common/ConditionalExplanationField";
import YesNoButtons from "../../common/YesNoButtons";

const HealthDeclarationStage: React.FC<JoinFormStageProps> = ({
  joinData,
  advanceForm,
  dispatchJoinData,
  handleFormStageUpdated,
}) => {
  const [conditionDeclared, setConditionDeclared] = React.useState(
    joinData.medicalConditions !== null
  );

  const updateConditionDetails = React.useCallback(
    (conditionDetails: string | null) => {
      dispatchJoinData({ medicalConditions: conditionDetails });
    },
    [dispatchJoinData]
  );

  // Validation

  const [validateConditionForm, setValidateConditionForm] = React.useState<
    () => void
  >();

  const validateStage = React.useCallback(() => {
    if (conditionDeclared && validateConditionForm !== undefined) {
      validateConditionForm();
    } else {
      advanceForm();
    }
  }, [conditionDeclared, advanceForm, validateConditionForm]);

  React.useEffect(() => {
    handleFormStageUpdated({
      submissionFunction: () => {
        validateStage();
      },
    });
  }, [handleFormStageUpdated, validateStage]);

  return (
    <Form>
      <Form.Label>
        <p>
          Have you ever been diagnosed with or treated for any of the medical
          conditions listed below?
        </p>
        <ul>
          {[
            "Acute Stress Reaction or an acute reaction to the stress caused by a trauma",
            "Suicidal thoughts or self harm",
            "Depression or anxiety",
            "Dementia",
            "Mania, bipolar disorder or a psychotic illness",
            "A personality disorder",
            "A neurological condition: for example, Multiple Sclerosis, Parkinson’s or Huntington’s diseases, or epilepsy",
            "Alcohol or drug abuse",
            "Any other mental or physical condition which might affect your safe possession of a firearm or shotgun",
          ].map((v, i) => (
            <li key={i}>{v}</li>
          ))}
        </ul>
        <p>
          If you are unsure about this declaration, please contact us using the
          contact form on the website and we can assist you.
        </p>
      </Form.Label>

      <div style={{ marginBottom: "1rem" }}>
        <YesNoButtons
          isYes={conditionDeclared}
          updateIsYes={setConditionDeclared}
          noLabel={
            "No, I haven't been diagnosed with or treated for any of" +
            " the conditions listed above"
          }
          yesLabel={
            "Yes, I have been diagnosed with or treated for any of" +
            " the conditions listed above"
          }
        />
      </div>

      <Form.Group controlId="health-declaration-info">
        <Form.Label>
          If yes, please give details below. The Committee will review the
          information you provide and advise you on how best to proceed.
          Disclosing a medical condition does not necessarily preclude you from
          joining the club.
        </Form.Label>
        <ConditionalExplanationField
          disabled={!conditionDeclared}
          data={joinData.medicalConditions}
          setData={updateConditionDetails}
          onFormRender={React.useCallback(
            ({ submitForm }) => {
              setValidateConditionForm(() => submitForm);
            },
            [setValidateConditionForm]
          )}
          onSubmit={React.useCallback(() => advanceForm(), [advanceForm])}
        />
      </Form.Group>
    </Form>
  );
};

export default HealthDeclarationStage;
