import React from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "./components/pages/HomePage";
import JoinFormPage from "./components/pages/JoinFormPage";
import NotFoundPage from "./components/pages/NotFoundPage";

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/joinform" element={<JoinFormPage />} />

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default AppRoutes;
