import React from "react";

import Navbar from "react-bootstrap/Navbar";

const Header: React.FC = () => (
  <Navbar bg="primary" variant="dark">
    <Navbar.Brand className="icrpc-title" style={{ fontWeight: 400 }}>
      Join ICRPC
    </Navbar.Brand>
  </Navbar>
);

export default Header;
