import React from "react";

export type StageColours = {
  complete: string;
  current: string;
  upcoming: string;
};

const DEFAULT_STAGE_COLOURS: StageColours = {
  complete: "#FFFFFF",
  current: "#FFFFFF",
  upcoming: "#000000",
};

const DEFAULT_STAGE_BG_COLOURS: StageColours = {
  complete: "var(--success)",
  current: "#333333",
  upcoming: "#DDDDDD",
};

const getStageColour = (
  colours: StageColours,
  indicatorIndex: number,
  currentStage: number
) =>
  indicatorIndex === currentStage
    ? colours.current
    : indicatorIndex < currentStage
    ? colours.complete
    : colours.upcoming;

interface FormStageIndicatorProps {
  currentStage: number;
  stageCount: number;
  stageColours?: StageColours;
  stageBackgroundColours?: StageColours;
}

const FormStageIndicator: React.FC<FormStageIndicatorProps> = ({
  currentStage,
  stageCount,
  stageColours = DEFAULT_STAGE_COLOURS,
  stageBackgroundColours = DEFAULT_STAGE_BG_COLOURS,
}) => (
  <div className="form-stage-indicator">
    {Array(stageCount)
      .fill(0)
      .map((v, i) => {
        const stageColour = getStageColour(stageColours, i, currentStage);
        const stageBackgroundColour = getStageColour(
          stageBackgroundColours,
          i,
          currentStage
        );

        return (
          <div key={i} className="form-stage-indicator-element">
            <div
              className="form-stage-indicator-element-bar"
              style={{
                borderTopColor: stageBackgroundColour,
              }}
            />
            <div
              className="form-stage-indicator-element-circle"
              style={{
                color: stageColour,
                backgroundColor: stageBackgroundColour,
              }}
            >
              {i + 1}
            </div>
          </div>
        );
      })}
  </div>
);

export default FormStageIndicator;
