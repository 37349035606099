import React from "react";
import moment from "moment";

import { Address, AddressType } from "../../../models";

import AddressDisplay from "../../common/AddressDisplay";
import SubmissionDataField from "./SubmissionDataField";

interface SubmissionAddressDisplayPropsPrimary {
  address: Omit<Address, "isPrimary">;
  isPrimary: true;
}

interface SubmissionAddressDisplayPropsNonPrimary {
  address: Omit<Address, "isPrimary">;
  isPrimary: false;
  addressIndex: number;
}

const SubmissionAddressDisplay: React.FC<
  SubmissionAddressDisplayPropsPrimary | SubmissionAddressDisplayPropsNonPrimary
> = (props) => {
  const { address, isPrimary } = props;

  const htmlAddressID = isPrimary
    ? "primary-address"
    : `other-address-${props.addressIndex}`;

  const addressDescription = isPrimary
    ? "Primary address"
    : `Additional address ${props.addressIndex}`;

  return (
    <div aria-labelledby={`${htmlAddressID}-details-label`}>
      <div className="sr-only" id={`${htmlAddressID}-details-label`}>
        {addressDescription} details
      </div>
      <SubmissionDataField
        field="Address"
        data={<AddressDisplay address={address} />}
        accessibleLabel={addressDescription}
        fieldId={`${htmlAddressID}-address`}
      />
      <SubmissionDataField
        field="Dates"
        data={
          <>
            {moment(address.dateFrom).format("LL")}&ndash;
            {address.dateTo !== null ? (
              moment(address.dateTo).format("LL")
            ) : (
              <em>Present</em>
            )}
          </>
        }
        accessibleLabel={`Dates lived at ${addressDescription}`}
        fieldId={`${htmlAddressID}-dates`}
      />
      <SubmissionDataField
        field="Address Type"
        data={
          address.addressType === AddressType.PERMANENT
            ? "Permanent"
            : address.addressType === AddressType.TEMPORARY
            ? "Temporary"
            : "UNKNOWN"
        }
        accessibleLabel={`${addressDescription} type`}
        fieldId={`${htmlAddressID}-addresstype`}
      />
    </div>
  );
};

export default SubmissionAddressDisplay;
