import React from "react";

import axios from "axios";
import moment from "moment";

const Footer: React.FC = () => {
  const [backend, setBackend] = React.useState<{
    environment: string;
    version: string;
  } | null>(null);

  React.useEffect(() => {
    axios
      .get("/api/version")
      .then((response) => {
        if (response !== null) {
          setBackend({
            environment: response.data.environment,
            version: response.data.version,
          });
        } else {
          setBackend(null);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setBackend(null);
      });
  }, []);

  return (
    <footer>
      <div className="text-center small">
      &copy; Imperial College &amp; St. Mary's Rifle &amp; Pistol Club
        2020&ndash;{moment().utc().year()}
      </div>
      <div
        className="text-center"
        style={{ color: "#BBBBBB", fontSize: "0.75rem" }}
      >
        <b>FE</b>: {process.env.NODE_ENV} -{" "}
        {process.env.REACT_APP_ICRPC_VERSION || "dev"}{" "}
        {backend !== null && (
          <>
            | <b>BE</b>: {backend.environment} - {backend.version}
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
