import React from "react";

export interface DebugBoxProps {
  title?: string;
  expandable?: boolean;
  startExpanded?: boolean;
}

const DebugBox: React.FC<DebugBoxProps> = ({
  children,
  title,
  expandable = false,
  startExpanded = true,
}) => {
  const [expanded, setExpanded] = React.useState(startExpanded);

  return process.env.NODE_ENV === "development" ? (
    <div
      style={{ margin: "0.5rem", padding: "0.5rem", border: "1px solid red" }}
    >
      <pre style={{ margin: 0, textAlign: "center", color: "red" }}>
        DEBUG
        {title !== undefined && `: ${title}`}
        {expandable && (
          <span onClick={() => setExpanded(!expanded)}>
            {" "}
            &mdash; {expanded ? "HIDE" : "EXPAND"}
          </span>
        )}
      </pre>
      {expanded && children}
    </div>
  ) : null;
};

export default DebugBox;
