import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface ConfirmModalProps {
  show: boolean;

  handleCancel: () => void;
  handleConfirm: () => void;

  confirmButtonText: string;
  confirmButtonVariant: "primary" | "success" | "danger";
  modalBody: React.ReactNode;
  modalTitle: string;

  onExited?: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show,
  handleCancel,
  handleConfirm,
  confirmButtonText,
  confirmButtonVariant,
  modalBody,
  modalTitle,
  onExited,
}) => {
  const modalID = React.useMemo(
    () => Math.random().toString(36).substr(2, 4),
    []
  );

  const modalTitleID = `confirm-modal-${modalID}-title`;

  return (
    <Modal
      show={show}
      onHide={() => handleCancel()}
      onExited={onExited}
      aria-labelledby={modalTitleID}
    >
      <Modal.Header>
        <Modal.Title id={modalTitleID}>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleCancel()} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => handleConfirm()} variant={confirmButtonVariant}>
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
