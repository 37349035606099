import React from "react";

import { Address } from "../../models";

interface AddressProps {
  address: Pick<
    Address,
    "line1" | "line2" | "town" | "region" | "country" | "postcode"
  >;
}

const AddressDisplay: React.FC<AddressProps> = ({ address }) => (
  <>
    {[
      address.line1,
      address.line2,
      address.town,
      address.region,
      address.country,
      address.postcode,
    ].map(
      (s, i) =>
        s && (
          <React.Fragment key={i}>
            {s} <br />
          </React.Fragment>
        )
    )}
  </>
);

export default AddressDisplay;
