import React from "react";

import Table from "react-bootstrap/Table";

import { Address } from "../../../models";

import TableAddressDisplay from "./TableAddressDisplay";

interface AddressHistoryTableProps {
  addresses: Address[];
  onAddressEdit: (a: Address) => void;
  onAddressDelete: (a: Address) => void;
  onAddressPromotion: (a: Address) => void;
}

const AddressHistoryTable: React.FC<AddressHistoryTableProps> = ({
  addresses,
  onAddressEdit,
  onAddressDelete,
  onAddressPromotion,
}) => {
  if (addresses.length > 0) {
    // primaryAddresses should only be 1 long so just get the first element
    const primaryAddress = addresses.filter((a) => a.isPrimary)[0];
    const additionalAddresses = addresses.filter((a) => !a.isPrimary);

    return (
      <>
        <Table size="sm">
          <tbody>
            <tr>
              <th scope="row">Primary Address</th>
              <td>
                {primaryAddress !== undefined ? (
                  <TableAddressDisplay
                    address={primaryAddress}
                    onAddressEdit={onAddressEdit}
                    onAddressDelete={onAddressDelete}
                  />
                ) : (
                  <em className="text-muted">No primary address defined</em>
                )}
              </td>
            </tr>

            {additionalAddresses.map((address, i) => (
              <tr key={i}>
                {/* Put the header in the first row only and set rowspan */}
                {i === 0 && (
                  <th scope="row" rowSpan={additionalAddresses.length}>
                    Additional Addresses
                  </th>
                )}
                <td>
                  <TableAddressDisplay
                    address={address}
                    onAddressEdit={onAddressEdit}
                    onAddressDelete={onAddressDelete}
                    onAddressPromotion={onAddressPromotion}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <hr />
      </>
    );
  } else {
    return (
      <div>
        <em className="text-muted">
          No addresses yet, add an address by clicking 'Add new address' below
        </em>
      </div>
    );
  }
};

export default AddressHistoryTable;
