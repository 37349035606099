import React from "react";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";

import Form from "react-bootstrap/Form";

import { ICUStatus } from "../../../models";
import { JoinFormStageProps } from "../JoinForm";
import { YupISO8601Date } from "../../../validation";

import RequiredAsterisk from "../../common/RequiredAsterisk";

const STATUS_STUDENT = "STUDENT";
const STATUS_ASSOCIATE = "ASSOCIATE";

const FIELD_REQUIRED = "This field is required";

const AboutYouFormSchema = Yup.object().shape({
  forenames: Yup.string().required(FIELD_REQUIRED),
  surname: Yup.string().required(FIELD_REQUIRED),
  preferredName: Yup.string().notRequired(),
  dateOfBirth: YupISO8601Date(true),
  townOfBirth: Yup.string().required(FIELD_REQUIRED),
  countryOfBirth: Yup.string().required(FIELD_REQUIRED),
  nationality: Yup.string().required(FIELD_REQUIRED),
  emailAddress: Yup.string()
    .email("Please enter a valid email address")
    .required(FIELD_REQUIRED),
  contactNumber: Yup.string().required(FIELD_REQUIRED),
  cid: Yup.string()
    .matches(/^(?:\d{8}|AM-\d{5})$/, { message: "Please enter a valid CID" })
    .required(FIELD_REQUIRED),
  icuStatus: Yup.string()
    .oneOf([STATUS_STUDENT, STATUS_ASSOCIATE])
    .required(FIELD_REQUIRED),
  collegeLogin: Yup.string().when("icuStatus", {
    is: STATUS_STUDENT,
    then: Yup.string().required(FIELD_REQUIRED),
    otherwise: Yup.string().notRequired(),
  }),
});

interface AboutYouFormValues {
  forenames: string;
  surname: string;
  preferredName: string;
  dateOfBirth: string;
  townOfBirth: string;
  countryOfBirth: string;
  nationality: string;
  emailAddress: string;
  contactNumber: string;
  cid: string;
  icuStatus: string;
  collegeLogin: string;
}

const AboutYouInnerForm: React.FC<
  JoinFormStageProps & FormikProps<AboutYouFormValues>
> = ({
  dispatchJoinData,
  handleFormStageUpdated,
  handleBlur,
  handleChange,
  handleSubmit,
  submitForm,
  errors,
  touched,
  values,
}) => {
  React.useEffect(() => {
    dispatchJoinData({
      forenames: values.forenames,
      surname: values.surname,
      preferredName: values.preferredName || null,
      dateOfBirth: values.dateOfBirth,
      townOfBirth: values.townOfBirth,
      countryOfBirth: values.countryOfBirth,
      nationality: values.nationality,
      emailAddress: values.emailAddress,
      contactNumber: values.contactNumber,
      cid: values.cid,
      icuStatus:
        values.icuStatus === STATUS_STUDENT
          ? ICUStatus.STUDENT
          : ICUStatus.ASSOCIATE,
      collegeLogin:
        values.icuStatus === STATUS_STUDENT
          ? values.collegeLogin
              .replace(/@imperial\.ac\.uk/g, "")
              .replace(/@ic\.ac\.uk/g, "")
          : null,
    });
  }, [dispatchJoinData, values]);

  React.useEffect(() => {
    handleFormStageUpdated({
      submissionFunction: () => {
        submitForm();
      },
    });
  }, [handleFormStageUpdated, submitForm]);

  return (
    <Form onSubmit={(e) => handleSubmit(e as React.FormEvent<HTMLFormElement>)}>
      <h3>Identity</h3>

      <Form.Group controlId="aboutYouForenames">
        <Form.Label>
          Forenames <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="forenames"
          type="text"
          autoComplete="given-name"
          isInvalid={touched.forenames && !!errors.forenames}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.forenames}
        />
        <Form.Text className="text-muted">
          Your first name followed by any middle names exactly as they appear on
          your passport or other UK-issued official documentation (e.g. a
          British Residency Permit)
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.forenames}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouSurname">
        <Form.Label>
          Surname <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="surname"
          type="text"
          autoComplete="family-name"
          isInvalid={touched.surname && !!errors.surname}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.surname}
        />
        <Form.Text className="text-muted">
          Your surname exactly as it appears on your passport or other UK-issued
          official documentation (e.g. a British Residency Permit)
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.surname}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouPreferredName">
        <Form.Label>Preferred Name</Form.Label>
        <Form.Control
          name="preferredName"
          type="text"
          autoComplete="nickname"
          isInvalid={touched.preferredName && !!errors.preferredName}
          placeholder={values.forenames.split(" ")[0] || undefined}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.preferredName}
        />
        <Form.Text className="text-muted">
          If you prefer to go by a name other than your official first name,
          please enter it here.
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.preferredName}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouDateOfBirth">
        <Form.Label>
          Date of Birth <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="dateOfBirth"
          type="date"
          autoComplete="bday"
          isInvalid={touched.dateOfBirth && !!errors.dateOfBirth}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.dateOfBirth}
        />
        <Form.Control.Feedback type="invalid">
          {errors.dateOfBirth}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouTownOfBirth">
        <Form.Label>
          Town of Birth <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="townOfBirth"
          type="text"
          autoComplete="off"
          isInvalid={touched.townOfBirth && !!errors.townOfBirth}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.townOfBirth}
        />
        <Form.Control.Feedback type="invalid">
          {errors.townOfBirth}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouCountryOfBirth">
        <Form.Label>
          Country of Birth <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="countryOfBirth"
          type="text"
          autoComplete="off"
          isInvalid={touched.countryOfBirth && !!errors.countryOfBirth}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.countryOfBirth}
        />
        <Form.Control.Feedback type="invalid">
          {errors.countryOfBirth}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouNationality">
        <Form.Label>
          Nationality <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="nationality"
          type="text"
          autoComplete="off"
          isInvalid={touched.nationality && !!errors.nationality}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.nationality}
        />
        <Form.Text className="text-muted">
          If you have multiple nationalities, please enter all of them separated
          by commas.
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.nationality}
        </Form.Control.Feedback>
      </Form.Group>

      <hr />

      <h3>Contact Info</h3>

      <Form.Group controlId="aboutYouEmailAddress">
        <Form.Label>
          Email address <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="emailAddress"
          type="email"
          autoComplete="email"
          isInvalid={touched.emailAddress && !!errors.emailAddress}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.emailAddress}
        />
        <Form.Control.Feedback type="invalid">
          {errors.emailAddress}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouContactNumber">
        <Form.Label>
          Contact number <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="contactNumber"
          type="text"
          autoComplete="tel"
          isInvalid={touched.contactNumber && !!errors.contactNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.contactNumber}
        />
        <Form.Text className="text-muted">
          We will only ever call you in relation to any trips you sign up for,
          for example if there are any last minute updates, you fail to turn up,
          or if we need to get in touch during the trip for whatever reason.
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.contactNumber}
        </Form.Control.Feedback>
      </Form.Group>

      <hr />

      <h3>College Info</h3>

      <Form.Group controlId="aboutYouCID">
        <Form.Label>
          CID <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="cid"
          type="text"
          autoComplete="off"
          isInvalid={touched.cid && !!errors.cid}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.cid}
        />
        {/* The {" "} is necessary to put a space before 'AM' */}
        <Form.Text className="text-muted">
          Imperial student, staff, and alumni: this is the 8 digit number which
          can be found on the front of your College ID card. Associate members:
          this is a number issued by the Union beginning with{" "}
          <span style={{ fontFamily: "monospace" }}>AM</span>
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.cid}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouICUStatus">
        <Form.Label>
          Status <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="icuStatus"
          as="select"
          autoComplete="off"
          isInvalid={touched.icuStatus && !!errors.icuStatus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.icuStatus}
        >
          <option value={STATUS_STUDENT}>Imperial student</option>
          <option value={STATUS_ASSOCIATE}>Associate member</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.icuStatus}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aboutYouCollegeLogin">
        <Form.Label>
          College login{" "}
          {values.icuStatus === STATUS_STUDENT && <RequiredAsterisk />}
        </Form.Label>
        <Form.Control
          name="collegeLogin"
          type="text"
          autoComplete="username"
          disabled={values.icuStatus !== STATUS_STUDENT}
          isInvalid={touched.collegeLogin && !!errors.collegeLogin}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.collegeLogin}
        />
        <Form.Text className="text-muted">
          Do not include the{" "}
          <span style={{ fontFamily: "monospace" }}>@ic.ac.uk</span> suffix
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          {errors.collegeLogin}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

const AboutYouForm = withFormik<JoinFormStageProps, AboutYouFormValues>({
  mapPropsToValues: ({ joinData }) => ({
    forenames: joinData.forenames,
    surname: joinData.surname,
    preferredName: joinData.preferredName || "",
    dateOfBirth: joinData.dateOfBirth,
    townOfBirth: joinData.townOfBirth,
    countryOfBirth: joinData.countryOfBirth,
    nationality: joinData.nationality,
    emailAddress: joinData.emailAddress,
    contactNumber: joinData.contactNumber,
    cid: joinData.cid,
    icuStatus:
      joinData.icuStatus === ICUStatus.STUDENT
        ? STATUS_STUDENT
        : STATUS_ASSOCIATE,
    collegeLogin: joinData.collegeLogin || "",
  }),
  validationSchema: AboutYouFormSchema,
  handleSubmit: (values, { props }) => {
    props.advanceForm();
  },
})(AboutYouInnerForm);

export default AboutYouForm;
