import React from "react";

import Form from "react-bootstrap/Form";

import { FirearmCertificate } from "../../../models";
import { JoinFormStageProps } from "../JoinForm";

import ConditionalExplanationField from "../../common/ConditionalExplanationField";
import FirearmsLicenceForm from "../firearmsLicensing/FirearmsLicenceForm";
import YesNoButtons from "../../common/YesNoButtons";

const FirearmsLicensingStage: React.FC<JoinFormStageProps> = ({
  joinData,
  advanceForm,
  dispatchJoinData,
  handleFormStageUpdated,
}) => {
  const [certRefusedState, setCertRefusedState] = React.useState(
    joinData.certificateRefusal !== null
  );

  const [hasFAC, setHasFAC] = React.useState(
    joinData.firearmCertificate !== null
  );

  const [hasSGC, setHasSGC] = React.useState(
    joinData.shotgunCertificate !== null
  );

  const updateCertRefusedData = React.useCallback(
    (certRefused: string | null) => {
      dispatchJoinData({ certificateRefusal: certRefused });
    },
    [dispatchJoinData]
  );

  const updateFACData = React.useCallback(
    (fac: FirearmCertificate | null) => {
      dispatchJoinData({ firearmCertificate: fac });
    },
    [dispatchJoinData]
  );

  const updateSGCData = React.useCallback(
    (sgc: FirearmCertificate | null) => {
      dispatchJoinData({ shotgunCertificate: sgc });
    },
    [dispatchJoinData]
  );

  // Validation

  const [validateCertRefused, setValidateCertRefused] =
    React.useState<() => void>();
  const [validateFAC, setValidateFAC] = React.useState<() => void>();
  const [validateSGC, setValidateSGC] = React.useState<() => void>();

  const [certRefusedValid, setCertRefusedValid] = React.useState(false);
  const [facValid, setFACValid] = React.useState(false);
  const [sgcValid, setSGCValid] = React.useState(false);

  const validateStage = React.useCallback(() => {
    setCertRefusedValid(false);
    setFACValid(false);
    setSGCValid(false);

    if (certRefusedState && validateCertRefused !== undefined) {
      validateCertRefused();
    } else {
      setCertRefusedValid(true);
    }

    if (hasFAC && validateFAC !== undefined) {
      validateFAC();
    } else {
      setFACValid(true);
    }

    if (hasSGC && validateSGC !== undefined) {
      validateSGC();
    } else {
      setSGCValid(true);
    }
  }, [
    certRefusedState,
    hasFAC,
    hasSGC,
    validateCertRefused,
    validateFAC,
    validateSGC,
  ]);

  React.useEffect(() => {
    handleFormStageUpdated({
      submissionFunction: () => {
        validateStage();
      },
    });
  }, [handleFormStageUpdated, validateStage]);

  React.useEffect(() => {
    if (certRefusedValid && facValid && sgcValid) {
      advanceForm();
    }
  }, [advanceForm, certRefusedValid, facValid, sgcValid]);

  return (
    <div>
      {/* Certificate refusal/revocation */}
      <div className="mb-3">
        <h2 className="h4">Firearms Licensing Declaration</h2>
        <Form.Label>
          Have you ever had an application for a firearm certificate (FAC) or
          shotgun certificate (SGC) refused by the police, or had such a
          certificate revoked
        </Form.Label>
        <YesNoButtons
          isYes={certRefusedState}
          updateIsYes={setCertRefusedState}
          noLabel={
            "No, I haven't had an application for an FAC or SGC refused" +
            " by the police or had such a certificate revoked"
          }
          yesLabel={
            "Yes, I have had an application for an FAC or SGC refused" +
            " by the police or had such a certificate revoked"
          }
        />
      </div>

      <Form.Group controlId="firearms-licensing-certificate-refusal-info">
        <Form.Label>
          If yes, please give details below. The Committee will review the
          specific circumstances which led to your previous refusal/revocation
          of a certificate. Please provide as much detail as possible.
        </Form.Label>
        <ConditionalExplanationField
          disabled={!certRefusedState}
          data={joinData.certificateRefusal}
          setData={updateCertRefusedData}
          onFormRender={React.useCallback(
            ({ submitForm }) => {
              setValidateCertRefused(() => submitForm);
            },
            [setValidateCertRefused]
          )}
          onSubmit={() => setCertRefusedValid(true)}
        />
      </Form.Group>

      {/* FAC */}
      <div className="mb-3">
        <h2 className="h4">FAC (Firearm Certificate)</h2>
        <Form.Label>
          Do you have a current UK Firearm Certificate (FAC)?
        </Form.Label>
        <YesNoButtons
          isYes={hasFAC}
          updateIsYes={setHasFAC}
          noLabel="No, I don't have a current UK Firearm Certificate"
          yesLabel="Yes, I do have a current UK Firearm Certificate"
        />
      </div>
      <FirearmsLicenceForm
        disabled={!hasFAC}
        certData={joinData.firearmCertificate}
        updateCertData={updateFACData}
        onFormRender={React.useCallback(
          ({ submitForm }) => {
            setValidateFAC(() => submitForm);
          },
          [setValidateFAC]
        )}
        onSubmit={() => setFACValid(true)}
        certificateType="FAC"
      />

      {/* SGC */}
      <div className="mb-3">
        <h2 className="h4">SGC (Shotgun Certificate)</h2>
        <Form.Label>
          Do you have a current UK Shotgun Certificate (SGC)?
        </Form.Label>
        <YesNoButtons
          isYes={hasSGC}
          updateIsYes={setHasSGC}
          noLabel="No, I don't have a current UK Shotgun Certificate"
          yesLabel="Yes, I do have a current UK Shotgun Certificate"
        />
      </div>
      <FirearmsLicenceForm
        disabled={!hasSGC}
        certData={joinData.shotgunCertificate}
        updateCertData={updateSGCData}
        onFormRender={React.useCallback(
          ({ submitForm }) => {
            setValidateSGC(() => submitForm);
          },
          [setValidateSGC]
        )}
        onSubmit={() => setSGCValid(true)}
        certificateType="SGC"
      />
    </div>
  );
};

export default FirearmsLicensingStage;
