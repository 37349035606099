import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

interface YesNoButtonsProps {
  isYes: boolean;
  updateIsYes: (isYes: boolean) => void;
  yesLabel?: string;
  noLabel?: string;
}

const YesNoButtons: React.FC<YesNoButtonsProps> = ({
  isYes,
  updateIsYes,
  yesLabel,
  noLabel,
}) => (
  <Form.Row>
    <Col xs={6}>
      {React.cloneElement(
        <Button
          block
          variant={isYes ? "secondary" : "success"}
          onClick={() => updateIsYes(false)}
        >
          No
        </Button>,
        { "aria-label": noLabel, "aria-selected": !isYes }
      )}
    </Col>
    <Col xs={6}>
      {React.cloneElement(
        <Button
          block
          variant={isYes ? "success" : "secondary"}
          onClick={() => updateIsYes(true)}
        >
          Yes
        </Button>,
        { "aria-label": yesLabel, "aria-selected": isYes }
      )}
    </Col>
  </Form.Row>
);

export default YesNoButtons;
