import React from "react";
import { Link } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import headerImg from "../../images/icrpc-logo-black-150px.jpg";

const HomePage: React.FC = () => (
  <Container>
    <Row>
      <Col>
        <div className="text-center my-2">
          <Image
            src={headerImg}
            alt="ICRPC Logo"
            style={{ maxHeight: "100px" }}
            fluid
          />
        </div>
        <p className="lead">Welcome to the Join ICRPC site</p>

        <p>
          This application is for prospective applicants of Imperial College &
          St. Mary's Rifle and Pistol Club to submit their details and apply to
          become a member.
        </p>

        <p>
          The following details are required to complete the form, please make
          sure you have all of these details before starting to fill in the
          form:
        </p>
        <ul>
          <li>Full legal name</li>
          <li>Date and place of birth</li>
          <li>Nationality (or nationalities)</li>
          <li>Contact details (phone number and email address)</li>
          <li>College details (CID, ICU member status, and College login)</li>
          <li>
            An uninterrupted address history covering the last 10 years,
            including both permanent (e.g. home) and temporary (e.g. term-time)
            addresses
          </li>
          <li>
            Details of any currently held UK Firearm Certificate (FAC) and/or
            Shotgun Certificate (SGC), as well as details of any time an
            application for an FAC or SGC has been refused, or such a
            certificate revoked
          </li>
          <li>
            Details of any physical, mental, or other health condition (past or
            present) that might affect the safe possession of a firearm or
            shotgun
          </li>
        </ul>

        <Alert variant="warning">
          <p>
            <strong>Important:</strong> in order for your application to be
            processed, you must be a member of{" "}
            <strong>SPI Rifle &amp; Pistol</strong> which in turn requires
            membership of <strong>Imperial Athletes</strong> for the current
            Union year (the Union year begins on the 1st August, and finishes on
            the following 31st July). If you are not members of both groups,
            then your application may be delayed.{" "}
            <a
              href="https://www.imperialcollegeunion.org/activities/imperial-athletes"
              target="_blank"
              rel="noreferrer"
            >
              Click here for more information on Imperial Athletes
            </a>
            , and{" "}
            <a
              href="https://www.imperialcollegeunion.org/activities/a-to-z/rifle-pistol"
              target="_blank"
              rel="noreferrer"
            >
              here for more information on SPI Rifle &amp; Pistol
            </a>
            .
          </p>
        </Alert>

        <Button block size="lg" variant="primary" as={Link} to="/joinform">
          Continue to application form
        </Button>
      </Col>
    </Row>
  </Container>
);

export default HomePage;
