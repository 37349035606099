import React from "react";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";

import Form from "react-bootstrap/Form";

const FIELD_REQUIRED = "This field is required";

const conditionalExplanationSchema = Yup.object().shape({
  explanation: Yup.string().required(FIELD_REQUIRED),
});

interface ConditionalExplanationFieldValues {
  explanation: string;
}

interface ConditionalExplanationFieldProps {
  disabled: boolean;

  data: string | null;
  setData: (data: string | null) => void;

  onFormRender?: (formDetails: { submitForm: () => void }) => void;
  onSubmit?: () => void;
}

const ConditionalExplanationFieldInner: React.FC<
  ConditionalExplanationFieldProps &
    FormikProps<ConditionalExplanationFieldValues>
> = ({
  // ConditionalExplanationFieldProps
  disabled,
  onFormRender,
  setData,
  // FormikProps
  errors,
  handleBlur,
  handleChange,
  resetForm,
  submitForm,
  touched,
  values,
}) => {
  React.useEffect(() => {
    if (disabled) {
      setData(null);
      resetForm();
    } else {
      setData(values.explanation);
    }
  }, [disabled, setData, resetForm, values]);

  React.useEffect(() => {
    if (onFormRender !== undefined) {
      onFormRender({
        submitForm: () => {
          submitForm();
        },
      });
    }
  }, [onFormRender, submitForm]);

  return (
    <>
      <Form.Control
        name="explanation"
        type="text"
        as="textarea"
        autoComplete="off"
        rows={3}
        disabled={disabled}
        isInvalid={touched.explanation && !!errors.explanation}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.explanation}
      />
      <Form.Control.Feedback type="invalid">
        {errors.explanation}
      </Form.Control.Feedback>
    </>
  );
};

const ConditionalExplanationField = withFormik<
  ConditionalExplanationFieldProps,
  ConditionalExplanationFieldValues
>({
  mapPropsToValues: ({ data, disabled }) => ({
    explanation: data === null || disabled ? "" : data,
  }),
  handleSubmit: (values, { props }) => {
    if (props.onSubmit !== undefined) {
      props.onSubmit();
    }
  },
  validationSchema: conditionalExplanationSchema,
})(ConditionalExplanationFieldInner);

export default ConditionalExplanationField;
