import React from "react";
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";

import Form from "react-bootstrap/Form";

import { FirearmCertificate } from "../../../models";
import { YupISO8601Date } from "../../../validation";

const FIELD_REQUIRED = "This field is required";

const firearmsLicenceSchema = Yup.object().shape({
  certificateNumber: Yup.string().required(FIELD_REQUIRED),
  issuingForce: Yup.string().required(FIELD_REQUIRED),
  expiryDate: YupISO8601Date(true),
});

interface FirearmsLicenceFormValues {
  certificateNumber: string;
  issuingForce: string;
  expiryDate: string;
}

interface FirearmsLicenceFormProps {
  disabled: boolean;

  certData: FirearmCertificate | null;
  updateCertData: (data: FirearmCertificate | null) => void;

  certificateType?: "FAC" | "SGC";

  onFormRender?: (formDetails: { submitForm: () => void }) => void;
  onSubmit?: () => void;
}

const FirearmsLicenceInnerForm: React.FC<
  FirearmsLicenceFormProps & FormikProps<FirearmsLicenceFormValues>
> = ({
  // FirearmsLicenceFormProps
  disabled,
  onFormRender,
  updateCertData,
  certificateType,
  // FormikProps
  errors,
  handleBlur,
  handleChange,
  resetForm,
  submitForm,
  touched,
  values,
}) => {
  React.useEffect(() => {
    if (disabled) {
      updateCertData(null);
      resetForm();
    } else {
      updateCertData({
        certificateNumber: values.certificateNumber,
        issuingForce: values.issuingForce,
        expiryDate: values.expiryDate,
      });
    }
  }, [disabled, updateCertData, resetForm, values]);

  React.useEffect(() => {
    if (onFormRender !== undefined) {
      onFormRender({
        submitForm: () => {
          submitForm();
        },
      });
    }
  }, [onFormRender, submitForm]);

  // Random suffixes for control IDs to distinguish multiple copies of the form
  // from each other on the same page (e.g. FAC and SGC)
  const controlIDSuffix = React.useMemo(
    () => Math.random().toString(36).substr(2, 4),
    []
  );

  // Either "FAC ", "SGC ", or ""
  const accessibleLabelPrefix =
    certificateType !== undefined ? `${certificateType} ` : "";

  return (
    <Form>
      <Form.Group
        controlId={`firearms-licence-certificate-number-${controlIDSuffix}`}
      >
        <Form.Label>
          <span className="sr-only">{accessibleLabelPrefix}</span>
          Certificate Number
        </Form.Label>
        <Form.Control
          name="certificateNumber"
          type="text"
          autoComplete="off"
          disabled={disabled}
          isInvalid={touched.certificateNumber && !!errors.certificateNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.certificateNumber}
        />
        <Form.Control.Feedback type="invalid">
          {errors.certificateNumber}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group
        controlId={`firearms-licence-issuing-force-${controlIDSuffix}`}
      >
        <Form.Label>
          <span className="sr-only">{accessibleLabelPrefix}</span>
          Issuing Force
        </Form.Label>
        <Form.Control
          name="issuingForce"
          type="text"
          autoComplete="off"
          disabled={disabled}
          isInvalid={touched.issuingForce && !!errors.issuingForce}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.issuingForce}
        />
        <Form.Control.Feedback type="invalid">
          {errors.issuingForce}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId={`firearms-licence-expiry-date-${controlIDSuffix}`}>
        <Form.Label>
          <span className="sr-only">{accessibleLabelPrefix}</span>
          Expiry Date
        </Form.Label>
        <Form.Control
          name="expiryDate"
          type="date"
          autoComplete="off"
          disabled={disabled}
          isInvalid={touched.expiryDate && !!errors.expiryDate}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.expiryDate}
        />
        <Form.Control.Feedback type="invalid">
          {errors.expiryDate}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

const FirearmsLicenceForm = withFormik<
  FirearmsLicenceFormProps,
  FirearmsLicenceFormValues
>({
  mapPropsToValues: ({ certData, disabled }) =>
    certData === null || disabled
      ? {
          certificateNumber: "",
          issuingForce: "",
          expiryDate: "",
        }
      : {
          certificateNumber: certData.certificateNumber,
          issuingForce: certData.issuingForce,
          expiryDate: certData.expiryDate,
        },
  handleSubmit: (values, { props }) => {
    if (props.onSubmit !== undefined) {
      props.onSubmit();
    }
  },
  validationSchema: firearmsLicenceSchema,
})(FirearmsLicenceInnerForm);

export default FirearmsLicenceForm;
