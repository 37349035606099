import React from "react";
import moment from "moment";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { Address } from "../../../models";
import { JoinFormStageProps } from "../JoinForm";

import DebugDataBox from "../../debug/DebugDataBox";

import AddressDisplay from "../../common/AddressDisplay";
import AddressFormModal from "../addressHistory/AddressFormModal";
import AddressHistoryTable from "../addressHistory/AddressHistoryTable";
import ConfirmModal from "../../common/ConfirmModal";

type AddressRange = { start: string; end: string | null };

enum AddressModal {
  DELETE,
  FORM,
}

const combineAddressRanges = (
  addressRanges: AddressRange[]
): AddressRange[] => {
  // Ensure we're actually dealing with at least 2 address ranges
  if (addressRanges.length < 2) {
    return addressRanges;
  }

  // Sort our input ranges by start date
  const ranges = addressRanges.sort((a, b) =>
    a.start < b.start ? -1 : a.start > b.start ? 1 : 0
  );

  const rangeStack: AddressRange[] = [];

  // Add our first range onto the stack
  rangeStack.push(ranges[0]);

  // For each of the subsequent ranges:
  ranges.slice(1).forEach((range) => {
    // Get range at top of stack
    const top = rangeStack[rangeStack.length - 1];

    // If the range at the top of the stack does not have an end date, then
    //   all subsequent ranges will overlap
    if (top.end === null) {
      return;
    }

    // If this range doesn't overlap (i.e. it starts after the top finishes),
    //   add it to the stack
    if (top.end < range.start) {
      // If this range doesn't overlap (i.e. it starts after the top finishes,
      //   add it to the stack)
      rangeStack.push(range);
    } else if (range.end === null || top.end < range.end) {
      // Otherwise, if this range has no end date or ends after the current
      //  range, extend the top range
      top.end = range.end;
      rangeStack.pop();
      rangeStack.push(top);
    }

    // If the range doesn't get caught by either of those if statements, it is
    //   fully contained within the top interval and can be discarded
  });

  return rangeStack;
};

const validateAddressRanges = (
  addressRanges: AddressRange[],
  thresholdDay: string
): { valid: boolean; errors: string[] } => {
  // If there are no address ranges, return that as the only error. It allows
  //   for a better message and means the later rules can assume at least one
  //   address range is present
  if (addressRanges.length === 0) {
    return {
      valid: false,
      errors: [
        "You must specify an uninterrupted address history for the last 10 years",
      ],
    };
  }

  const errors: string[] = [];
  const thresholdDayFormatted = moment(thresholdDay).format("LL");

  // At least one address must be a current address
  if (addressRanges.every((ar) => ar.end !== null)) {
    errors.push(`You must specify at least one current address`);
  }

  // Ensure that the address history includes the threshold day by testing if
  //   every address range doesn't include the threshold
  if (
    addressRanges.every(
      (ar) =>
        !(
          ar.start <= thresholdDay &&
          (ar.end === null || thresholdDay <= ar.end)
        )
    )
  ) {
    errors.push(`Your address history must include ${thresholdDayFormatted}`);
  }

  // The last 10 years should be continuous - if we have any ranges ending after
  //   the threshold day there must be a gap
  if (!addressRanges.every((ar) => ar.end === null || ar.end < thresholdDay)) {
    errors.push(
      `There must not be any gaps in your address history after ${thresholdDayFormatted}`
    );
  }
  return { valid: errors.length === 0, errors };
};

const AddressHistoryStage: React.FC<JoinFormStageProps> = ({
  joinData,
  advanceForm,
  dispatchJoinData,
  handleFormStageUpdated,
}) => {
  const [showAddressModal, setShowAddressModal] =
    React.useState<AddressModal | null>(null);

  const [targetAddress, setTargetAddress] = React.useState<Address>();

  const resetAddressModals = () => {
    setShowAddressModal(null);
    setTargetAddress(undefined);
  };

  const handleEditAddress = (address: Address) => {
    setTargetAddress(address);
    setShowAddressModal(AddressModal.FORM);
  };

  const handleDeleteAddress = (address: Address) => {
    setTargetAddress(address);
    setShowAddressModal(AddressModal.DELETE);
  };

  const handleConfirmDeleteAddress = (address: Address) => {
    dispatchJoinData({
      addresses: joinData.addresses.filter((a) => a !== address),
    });
    resetAddressModals();
  };

  const handleAddressPromotion = (address: Address) => {
    dispatchJoinData({
      addresses: joinData.addresses.map((a) => ({
        ...a,
        isPrimary: a === address,
      })),
    });
  };

  const handleAddressSubmission = (address: Address) => {
    // If we're not editing, add the address - otherwise replace the original
    if (targetAddress === undefined) {
      // If this is a current address and we don't yet have a primary, set this
      //   as the primary address
      dispatchJoinData({
        addresses: joinData.addresses.concat(
          address.dateTo === null &&
            joinData.addresses.filter((a) => a.isPrimary).length === 0
            ? { ...address, isPrimary: true }
            : address
        ),
      });
    } else {
      dispatchJoinData({
        addresses: joinData.addresses.map((a) =>
          a === targetAddress ? address : a
        ),
      });
    }
  };

  const addressRanges = joinData.addresses.map((a) => ({
    start: a.dateFrom,
    end: a.dateTo,
  }));

  const combinedRanges = combineAddressRanges(addressRanges);

  const todayMinus10Years = moment().subtract(10, "years");

  const addressIssues = validateAddressRanges(
    combinedRanges,
    todayMinus10Years.format("YYYY-MM-DD")
  );

  const [showAllAddressIssues, setShowAllAddressIssues] = React.useState(false);

  const addressIssuesAlertBox = React.useRef<HTMLDivElement>(null);

  const validateStage = React.useCallback(() => {
    if (addressIssues.valid) {
      advanceForm();
    } else {
      setShowAllAddressIssues(true);

      const alertBox = addressIssuesAlertBox.current;

      if (alertBox !== null) {
        window.scrollTo(0, alertBox.offsetTop);
      }
    }
  }, [addressIssues.valid, advanceForm]);

  React.useEffect(() => {
    handleFormStageUpdated({
      submissionFunction: () => {
        validateStage();
      },
    });
  }, [handleFormStageUpdated, validateStage]);

  return (
    <>
      <DebugDataBox
        title="Address validation"
        data={{
          addressRanges: addressRanges,
          combinedRanges: combinedRanges,
          today: moment().toISOString(),
          todayMinus10Years: todayMinus10Years.toISOString(),
          addressIssues,
        }}
      />
      <div>
        <p className="lead">
          In order to process your application, we require your full
          uninterrupted address history covering the last 10 years.
        </p>
        <ul>
          <li>
            Please include any temporary/term-time addresses such as Halls of
            Residence as well as your permanent home addresses
          </li>
          <li>
            You may specify addresses with overlapping date ranges&mdash;for
            example a term-time address overlapping with a permanent home
            address
          </li>
          <li>
            You may specify multiple <em>current</em> addresses&mdash;for
            example a current term-time address and a current home address
          </li>
          <li>
            You must specify at least one current address, and you must
            designate exactly one current address as your{" "}
            <strong>primary</strong> address
          </li>
        </ul>
        <p>
          Should you have any questions, please contact the Club for guidance.
        </p>
      </div>
      <hr />

      {addressIssues.errors.length > 0 &&
        (joinData.addresses.length > 0 || showAllAddressIssues) && (
          <Alert ref={addressIssuesAlertBox} variant="warning">
            <strong>Your address history is incomplete</strong>
            <ul>
              {addressIssues.errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </Alert>
        )}

      {addressIssues.valid && (
        <Alert variant="success">
          Your address history is complete, please continue
        </Alert>
      )}

      <div>
        <AddressHistoryTable
          addresses={joinData.addresses}
          onAddressEdit={handleEditAddress}
          onAddressDelete={handleDeleteAddress}
          onAddressPromotion={handleAddressPromotion}
        />
      </div>

      <div className="mt-2">
        <Button
          block
          variant="success"
          onClick={() => setShowAddressModal(AddressModal.FORM)}
        >
          Add new address
        </Button>
      </div>

      <AddressFormModal
        show={showAddressModal === AddressModal.FORM}
        setShow={(s) => setShowAddressModal(s ? AddressModal.FORM : null)}
        editingAddress={targetAddress}
        setEditingAddress={setTargetAddress}
        onAddressSubmit={handleAddressSubmission}
      />

      {targetAddress !== undefined && (
        <ConfirmModal
          show={
            showAddressModal === AddressModal.DELETE &&
            targetAddress !== undefined
          }
          handleCancel={() => setShowAddressModal(null)}
          handleConfirm={() => handleConfirmDeleteAddress(targetAddress)}
          onExited={() => setTargetAddress(undefined)}
          confirmButtonText="Delete"
          confirmButtonVariant="danger"
          modalTitle="Delete Address"
          modalBody={
            <>
              <p>Are you sure you want to delete the following address:</p>
              <div className="pl-3">
                <AddressDisplay address={targetAddress} />
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default AddressHistoryStage;
