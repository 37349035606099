import React from "react";
import moment from "moment";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { Address, AddressType } from "../../../models";

import AddressDisplay from "../../common/AddressDisplay";

interface TableAddressDisplayProps {
  address: Address;
  onAddressEdit: (a: Address) => void;
  onAddressDelete: (a: Address) => void;
  onAddressPromotion?: (a: Address) => void;
}

const TableAddressDisplay: React.FC<TableAddressDisplayProps> = ({
  address,
  onAddressDelete,
  onAddressEdit,
  onAddressPromotion,
}) => (
  <div>
    <div>
      <span className="small">Address:</span>
      <br />
      <AddressDisplay address={address} />
    </div>
    <div className="mt-1">
      <span className="small">Dates:</span>
      <br />
      {moment(address.dateFrom).format("LL")}&ndash;
      {address.dateTo !== null ? (
        moment(address.dateTo).format("LL")
      ) : (
        <em>Present</em>
      )}
    </div>
    <div className="mt-1">
      <span className="small">Type:</span>
      <br />

      {address.addressType === AddressType.PERMANENT
        ? "Permanent"
        : "Temporary"}
    </div>
    <Form.Row className="mt-2">
      <Col sm={12} lg={4}>
        {onAddressPromotion !== undefined && address.dateTo === null ? (
          <Button
            block
            variant="primary"
            onClick={() => onAddressPromotion(address)}
            className="mb-2 mb-lg-0"
          >
            Set as primary address
          </Button>
        ) : (
          <>&nbsp;</>
        )}
      </Col>

      <Col sm={12} lg={4}>
        <Button
          block
          variant="secondary"
          onClick={() => onAddressEdit(address)}
          className="mb-2 mb-lg-0"
        >
          Edit address
        </Button>
      </Col>
      <Col sm={12} lg={4}>
        <Button block variant="danger" onClick={() => onAddressDelete(address)}>
          Delete address
        </Button>
      </Col>
    </Form.Row>
  </div>
);

export default TableAddressDisplay;
