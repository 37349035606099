import React from "react";
import { withFormik, FormikProps } from "formik";
import moment from "moment";
import * as Yup from "yup";

import Form from "react-bootstrap/Form";

import { Address, AddressType } from "../../../models";
import { YupISO8601Date } from "../../../validation";

import RequiredAsterisk from "../../common/RequiredAsterisk";
import YesNoButtons from "../../common/YesNoButtons";

const ADDRESS_TYPE_PERMANENT = "PERMANENT";
const ADDRESS_TYPE_TEMPORARY = "TEMPORARY";

const FIELD_REQUIRED = "This field is required";

const UK_COUNTRY_VALUES = [
  "United Kingdom",
  "UK",
  "U.K",
  "U.K.",
  "England",
  "Scotland",
  "Wales",
  "Northern Ireland",
  "NI",
  "N.I",
  "N.I.",
].map((c) => c.toLocaleLowerCase());

// Validation schema
const AddressSchema = (postcodeRequired: boolean, dateToRequired: boolean) =>
  Yup.object().shape({
    line1: Yup.string().required(FIELD_REQUIRED),
    line2: Yup.string().notRequired(),
    town: Yup.string().required(FIELD_REQUIRED),
    region: Yup.string().notRequired(),
    country: Yup.string().required(FIELD_REQUIRED),
    postcode: postcodeRequired
      ? Yup.string().required(FIELD_REQUIRED)
      : Yup.string().notRequired(),
    dateFrom: YupISO8601Date(true),
    dateTo: dateToRequired
      ? YupISO8601Date(true).test(
          "dateto-after-datefrom",
          "'Date To' must be after 'Date From'",
          ((value: any, context: any) =>
            moment(value, "YYYY-MM-DD", true).diff(
              moment(context.parent.dateFrom, "YYYY-MM-DD", true)
            ) > 0) as Yup.TestFunction
        )
      : Yup.string().notRequired(),
    addressType: Yup.string()
      .oneOf([ADDRESS_TYPE_PERMANENT, ADDRESS_TYPE_TEMPORARY])
      .required(FIELD_REQUIRED),
  });

interface AddressFormValues {
  line1: string;
  line2: string;
  town: string;
  region: string;
  country: string;
  postcode: string;
  dateFrom: string;
  dateTo: string;
  addressType: string;
}

interface AddressFormProps {
  isCurrentAddress: boolean;
  setIsCurrentAddress: (isCurrentAddress: boolean) => void;

  isUKAddress: boolean;
  setIsUKAddress: (isUKAddress: boolean) => void;

  editingAddress?: Address;

  dispatchAddressData: (a: Partial<Address>) => void;

  onFormRender?: (formDetails: { submitForm: () => void }) => void;
  onSubmit?: () => void;
}

const AddressFormInner: React.FC<
  AddressFormProps & FormikProps<AddressFormValues>
> = ({
  // AddressFormProps
  isCurrentAddress,
  setIsCurrentAddress,
  isUKAddress,
  setIsUKAddress,
  dispatchAddressData,
  onFormRender,
  // FormikProps
  errors,
  handleBlur,
  handleChange,
  setFieldError,
  setFieldValue,
  submitForm,
  touched,
  values,
}) => {
  React.useEffect(() => {
    dispatchAddressData({
      line1: values.line1,
      line2: values.line2 || null,
      town: values.town,
      region: values.region || null,
      country: values.country,
      postcode: values.postcode || null,
      dateFrom: values.dateFrom,
      dateTo: isCurrentAddress ? null : values.dateTo,
      addressType:
        values.addressType === ADDRESS_TYPE_PERMANENT
          ? AddressType.PERMANENT
          : AddressType.TEMPORARY,
    });
  }, [dispatchAddressData, isCurrentAddress, isUKAddress, values]);

  React.useEffect(() => {
    if (onFormRender !== undefined) {
      onFormRender({
        submitForm: () => {
          submitForm();
        },
      });
    }
  }, [onFormRender, submitForm]);

  // Clear any field errors for fields that are conditionally validated on
  // condition change
  React.useEffect(() => {
    // If now a current address, clear any dateTo errors
    if (isCurrentAddress) {
      setFieldError("dateTo", "");
    }

    // If now NOT a UK address, clear any postcode errors
    if (!isUKAddress) {
      setFieldError("postcode", "");
    }
  }, [setFieldError, isCurrentAddress, isUKAddress]);

  const [isUKAddressButtonsTouched, setIsUKAddressButtonsTouched] =
    React.useState(false);

  const handleUpdateIsUKAddress = (newIsUKAddress: boolean) => {
    setIsUKAddressButtonsTouched(true);
    setIsUKAddress(newIsUKAddress);
  };

  // Update the 'country' field value when isUKAddress changes, but only if
  // we've touched the isUKAddress buttons to prevent country from an edited
  // address from being overwritten
  React.useEffect(() => {
    if (isUKAddressButtonsTouched) {
      setFieldValue("country", isUKAddress ? "United Kingdom" : "");
    }
  }, [setFieldValue, isUKAddress, isUKAddressButtonsTouched]);

  const handleCountryBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      !isUKAddress &&
      UK_COUNTRY_VALUES.includes(values.country.toLocaleLowerCase())
    ) {
      setIsUKAddress(true);
      setFieldValue("country", "United Kingdom");
    }

    handleBlur(e);
  };

  return (
    <Form aria-label="Address Form">
      <Form.Group controlId="addressLine1">
        <Form.Label>
          Address Line 1 <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="line1"
          type="text"
          autoComplete="address-line1"
          isInvalid={touched.line1 && !!errors.line1}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.line1}
        />
        <Form.Control.Feedback type="invalid">
          {errors.line1}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="addressLine2">
        <Form.Label>Address Line 2</Form.Label>
        <Form.Control
          name="line2"
          type="text"
          autoComplete="address-line2"
          isInvalid={touched.line2 && !!errors.line2}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.line2}
        />
        <Form.Control.Feedback type="invalid">
          {errors.line2}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="addressTown">
        <Form.Label>
          Town <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="town"
          type="text"
          autoComplete="address-level2"
          isInvalid={touched.town && !!errors.town}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.town}
        />
        <Form.Control.Feedback type="invalid">
          {errors.town}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="addressRegion">
        <Form.Label>Region</Form.Label>
        <Form.Control
          name="region"
          type="text"
          autoComplete="address-level1"
          isInvalid={touched.region && !!errors.region}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.region}
        />
        <Form.Control.Feedback type="invalid">
          {errors.region}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Is this address in the United Kingdom?</Form.Label>
        <YesNoButtons
          isYes={isUKAddress}
          updateIsYes={handleUpdateIsUKAddress}
          yesLabel="This is a UK address"
          noLabel="This isn't a UK address"
        />
        <Form.Text className="text-muted">
          This includes England, Scotland, Wales, and Northern Ireland but not
          British Overseas Territories or Crown Dependencies
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="addressCountry">
        <Form.Label>
          Country <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="country"
          type="text"
          autoComplete="country-name"
          isInvalid={touched.country && !!errors.country}
          onBlur={handleCountryBlur}
          onChange={handleChange}
          value={values.country}
          disabled={isUKAddress}
        />
        <Form.Control.Feedback type="invalid">
          {errors.country}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="addressPostcode">
        <Form.Label>Postcode {isUKAddress && <RequiredAsterisk />}</Form.Label>
        <Form.Control
          name="postcode"
          type="text"
          autoComplete="postal-code"
          isInvalid={touched.postcode && !!errors.postcode}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.postcode}
        />
        <Form.Control.Feedback type="invalid">
          {errors.postcode}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="addressDateFrom">
        <Form.Label>
          Date From <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="dateFrom"
          type="date"
          autoComplete="off"
          isInvalid={touched.dateFrom && !!errors.dateFrom}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.dateFrom}
        />
        <Form.Control.Feedback type="invalid">
          {errors.dateFrom}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Is this a current address?</Form.Label>
        <YesNoButtons
          isYes={isCurrentAddress}
          updateIsYes={(isYes) => setIsCurrentAddress(isYes)}
          yesLabel="This is a current address"
          noLabel="This isn't a current address"
        />
        <Form.Text className="text-muted">
          This includes both home addresses and term-time/halls addresses where
          you are currently registered as living (e.g. addresses on bank
          accounts, driving licence, and utility bills)
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="addressDateTo">
        <Form.Label>
          Date To {!isCurrentAddress && <RequiredAsterisk />}
        </Form.Label>
        <Form.Control
          name="dateTo"
          type="date"
          autoComplete="off"
          isInvalid={touched.dateTo && !!errors.dateTo}
          onBlur={handleBlur}
          onChange={handleChange}
          value={isCurrentAddress ? "" : values.dateTo}
          disabled={isCurrentAddress}
        />
        <Form.Control.Feedback type="invalid">
          {errors.dateTo}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="addressType">
        <Form.Label>Address Type</Form.Label>
        <Form.Control
          name="addressType"
          as="select"
          autoComplete="off"
          isInvalid={touched.addressType && !!errors.addressType}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.addressType}
        >
          <option value={ADDRESS_TYPE_PERMANENT}>
            Permanent (e.g. home address)
          </option>
          <option value={ADDRESS_TYPE_TEMPORARY}>
            Temporary (e.g. term-time only/halls)
          </option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.addressType}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

const AddressForm = withFormik<AddressFormProps, AddressFormValues>({
  mapPropsToValues: ({ editingAddress, isUKAddress }) => {
    if (editingAddress !== undefined) {
      // If we've been given an address (i.e. we're editing), use it to
      // pre-populate the inputs
      return {
        line1: editingAddress.line1,
        line2: editingAddress.line2 || "",
        town: editingAddress.town,
        region: editingAddress.region || "",
        country: editingAddress.country,
        postcode: editingAddress.postcode || "",
        dateFrom: editingAddress.dateFrom,
        dateTo: editingAddress.dateTo || "",
        addressType:
          editingAddress.addressType === AddressType.PERMANENT
            ? ADDRESS_TYPE_PERMANENT
            : ADDRESS_TYPE_TEMPORARY,
      };
    } else {
      // Default values for address inputs if we're not editing an address
      return {
        line1: "",
        line2: "",
        town: "",
        region: "",
        country: isUKAddress ? "United Kingdom" : "",
        postcode: "",
        dateFrom: "",
        dateTo: "",
        addressType: ADDRESS_TYPE_PERMANENT,
      };
    }
  },
  handleSubmit: (values, { props }) => {
    if (props.onSubmit !== undefined) {
      props.onSubmit();
    }
  },
  validationSchema: (props: AddressFormProps) =>
    AddressSchema(props.isUKAddress, !props.isCurrentAddress),
})(AddressFormInner);

export default AddressForm;
