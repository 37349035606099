import React from "react";
import axios, { AxiosError } from "axios";
import { Link } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { ICUStatus, JoinData } from "../../models";

import FormStageIndicator from "./FormStageIndicator";

import AboutYouStage from "./stages/AboutYouStage";
import AddressHistoryStage from "./stages/AddressHistoryStage";
import FirearmsLicensingStage from "./stages/FirearmsLicensingStage";
import HealthDeclarationStage from "./stages/HealthDeclarationStage";
import IntroductionStage from "./stages/IntroductionStage";
import SubmissionStage from "./stages/SubmissionStage";
import DebugDataBox from "../debug/DebugDataBox";
import DebugBox from "../debug/DebugBox";

type FormStageUpdatedFunc = (props: { submissionFunction: () => void }) => void;

export interface JoinFormStageProps {
  joinData: JoinData;
  dispatchJoinData: (jd: Partial<JoinData>) => void;
  handleFormStageUpdated: FormStageUpdatedFunc;
  advanceForm: () => void;
}

const joinDataReducer = (
  currentJoinData: JoinData,
  partialJoinData: Partial<JoinData>
) => {
  return {
    ...currentJoinData,
    ...partialJoinData,
  };
};

const defaultJoinData: JoinData = {
  forenames: "",
  surname: "",
  preferredName: null,
  dateOfBirth: "",
  townOfBirth: "",
  countryOfBirth: "",
  nationality: "",
  emailAddress: "",
  contactNumber: "",
  cid: "",
  icuStatus: ICUStatus.STUDENT,
  collegeLogin: "",
  addresses: [],
  certificateRefusal: null,
  firearmCertificate: null,
  shotgunCertificate: null,
  medicalConditions: null,
};

const formStages: {
  title: string;
  component: React.ComponentType<JoinFormStageProps>;
}[] = [
  { title: "Introduction", component: IntroductionStage },
  { title: "About You", component: AboutYouStage },
  { title: "Address History", component: AddressHistoryStage },
  { title: "Firearms Licensing", component: FirearmsLicensingStage },
  { title: "Health Declaration", component: HealthDeclarationStage },
  { title: "Submission", component: SubmissionStage },
];

const JoinForm: React.FC = () => {
  // Form Data
  const [joinData, dispatchJoinData] = React.useReducer(
    joinDataReducer,
    defaultJoinData
  );

  // Stage handling
  const [currentStageIndex, setCurrentStageIndex] = React.useState(0);
  const { title: currentStageTitle, component: CurrentStage } = formStages[
    currentStageIndex
  ];

  const [
    formStageSubmissionFunction,
    setFormStageSubmissionFunction,
  ] = React.useState<() => void>();

  const gotoPreviousStage = () => {
    setFormStageSubmissionFunction(undefined);
    setCurrentStageIndex(Math.max(0, currentStageIndex - 1));
  };

  const gotoNextStage = () => {
    setFormStageSubmissionFunction(undefined);
    setCurrentStageIndex(
      Math.min(currentStageIndex + 1, formStages.length - 1)
    );
  };

  const handleFormStageUpdated: FormStageUpdatedFunc = React.useCallback(
    ({ submissionFunction }) => {
      setFormStageSubmissionFunction(() => submissionFunction);
    },
    [setFormStageSubmissionFunction]
  );

  const handlePreviousClicked = () => {
    setSubmissionState(undefined);
    gotoPreviousStage();
  };

  const handleNextClicked = () => {
    setSubmissionState(undefined);

    if (formStageSubmissionFunction !== undefined) {
      formStageSubmissionFunction();
    } else {
      gotoNextStage();
    }
  };

  const advanceForm = React.useCallback(gotoNextStage, [currentStageIndex]);

  // Submission
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submissionState, setSubmissionState] = React.useState<{
    success: boolean;
    error: string | null;
  }>();

  const handleSubmit = () => {
    console.log("Submitting...");
    console.log(joinData);

    setIsSubmitting(true);
    setSubmissionState(undefined);

    axios
      .post("/api/join", joinData)
      .then(() => {
        setSubmissionState({ success: true, error: null });
      })
      .catch((error: AxiosError) => {
        console.error("Error: ", error);
        setSubmissionState({
          success: false,
          error:
            error.response?.status === 400
              ? "Data failed validation, ensure the form has been filled in correctly"
              : error.response?.status === 500
              ? "The server encountered an error whilst processing the application"
              : error.toString(),
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div>
      <DebugDataBox title={"joinData"} data={joinData} />

      {submissionState === undefined || !submissionState.success ? (
        <>
          <FormStageIndicator
            currentStage={currentStageIndex}
            stageCount={formStages.length}
          />
          <div>
            <div className="h2">{currentStageTitle}</div>
            <CurrentStage
              joinData={joinData}
              dispatchJoinData={dispatchJoinData}
              handleFormStageUpdated={handleFormStageUpdated}
              advanceForm={advanceForm}
            />
          </div>

          <hr />

          {submissionState !== undefined && submissionState.error !== null && (
            <Row>
              <Col>
                <Alert variant="danger">
                  <p>
                    <strong>
                      An error occurred during submission. Please try again
                      later, and if the error persists please contact the club
                      using the <Link to="/contact">contact page</Link>, being
                      sure to include the error details given below.
                    </strong>
                  </p>
                  <p>{submissionState.error}</p>
                </Alert>
              </Col>
            </Row>
          )}

          <Form.Row>
            <Col>
              <Button
                block
                className="mr-1"
                variant="secondary"
                disabled={currentStageIndex === 0}
                onClick={() => handlePreviousClicked()}
              >
                Previous
              </Button>
            </Col>
            <Col>
              {currentStageIndex === formStages.length - 1 ? (
                <Button
                  block
                  variant="success"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              ) : (
                <Button block onClick={() => handleNextClicked()}>
                  Next
                </Button>
              )}
            </Col>
          </Form.Row>

          <DebugBox title="Skip validation nav">
            <Form.Row>
              <Col>
                <Button
                  block
                  className="mr-1"
                  variant="warning"
                  disabled={currentStageIndex === 0}
                  onClick={gotoPreviousStage}
                >
                  Skip Back
                </Button>
              </Col>
              <Col>
                <Button
                  block
                  variant="warning"
                  disabled={currentStageIndex === formStages.length - 1}
                  onClick={gotoNextStage}
                >
                  Skip Forward
                </Button>
              </Col>
            </Form.Row>
          </DebugBox>
        </>
      ) : (
        <Alert variant="success">
          <div style={{ fontSize: "x-large", textAlign: "center" }}>
            <strong>Submission Successful!</strong>
          </div>
          <hr />
          <div>
            <p>
              Your application has been successfully submitted to the Club
              Committee for consideration. Look out for an email from us soon!
            </p>
            <p>
              The first time you come to a club session, before you will be
              allowed to take part in any shooting activities, you will be
              required to sign a declaration stating the following:
            </p>
            <ul>
              <li>
                that you will abide by the constitution and byelaws of Imperial
                College &amp; St. Mary's Rifle &amp; Pistol Club
              </li>
              <li>
                that you have never had an application for a firearm or shotgun
                certificate refused by the police or had such a certificate
                revoked, and that you are not prohibited from possessing a
                firearm or ammunition by virtue of{" "}
                <a
                  href="https://www.legislation.gov.uk/ukpga/1968/27/section/21"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Section 21 of the Firearms Act 1968 as amended
                </a>
                .
              </li>
            </ul>
            <p>
              If you are unsure about anything stated above, please don't
              hesistate to <Link to="/contact">contact us</Link>.
            </p>
            <p>
              <Link to="/">Click here to return to the Home page</Link>
            </p>
          </div>
        </Alert>
      )}
    </div>
  );
};

export default JoinForm;
