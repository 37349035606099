import React from "react";
import moment from "moment";

import Table from "react-bootstrap/Table";

import { JoinFormStageProps } from "../JoinForm";
import { ICUStatus } from "../../../models";

import SubmissionAddressDisplay from "../submission/SubmissionAddressDisplay";
import SubmissionDataField from "../submission/SubmissionDataField";

const SubmissionStage: React.FC<JoinFormStageProps> = ({ joinData }) => {
  // primaryAddresses should only be 1 long so just get the first element
  const primaryAddress = joinData.addresses.filter((a) => a.isPrimary)[0];
  const additionalAddresses = joinData.addresses.filter((a) => !a.isPrimary);

  return (
    <>
      <h3>About You</h3>
      <Table size="sm">
        <tbody>
          <tr>
            <th scope="row">Identity</th>
            <td>
              <SubmissionDataField
                field="Forenames"
                data={joinData.forenames}
                fieldId="forenames"
              />
              <SubmissionDataField
                field="Surname"
                data={joinData.surname}
                fieldId="surname"
              />
              {joinData.preferredName !== null && (
                <SubmissionDataField
                  field="Preferred Name"
                  data={joinData.preferredName}
                  fieldId="preferred-name"
                />
              )}
              <SubmissionDataField
                field="Date of Birth"
                data={moment(joinData.dateOfBirth).format("LL")}
                fieldId="date-of-birth"
              />
              <SubmissionDataField
                field="Place of Birth"
                data={`${joinData.townOfBirth}, ${joinData.countryOfBirth}`}
                fieldId="place-of-birth"
              />
              <SubmissionDataField
                field="Nationality"
                data={joinData.nationality}
                fieldId="nationality"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Contact Info</th>
            <td>
              <SubmissionDataField
                field="Email address"
                data={joinData.emailAddress}
                fieldId="email-address"
              />
              <SubmissionDataField
                field="Contact number"
                data={joinData.contactNumber}
                fieldId="contact-number"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">College info</th>
            <td>
              <SubmissionDataField
                field="CID"
                data={joinData.cid}
                fieldId="cid"
              />
              <SubmissionDataField
                field="Status"
                data={
                  joinData.icuStatus === ICUStatus.STUDENT
                    ? "Imperial Student"
                    : "Associate Member"
                }
                fieldId="icu-status"
              />
              {joinData.collegeLogin !== null && (
                <SubmissionDataField
                  field="Login"
                  data={joinData.collegeLogin || "no login?"}
                  fieldId="college-login"
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>

      <h3>Address History</h3>

      {joinData.addresses.length > 0 ? (
        <Table size="sm">
          <tbody>
            <tr>
              <th scope="row" aria-hidden="true">
                Primary Address
              </th>
              <td>
                {primaryAddress !== undefined ? (
                  <SubmissionAddressDisplay
                    address={primaryAddress}
                    isPrimary={true}
                  />
                ) : (
                  <em className="text-muted">No primary address defined</em>
                )}
              </td>
            </tr>

            {additionalAddresses.map((a, i, addresses) => (
              <tr key={i}>
                {/* Put the header in the first row only and set rowspan */}
                {i === 0 && (
                  <th scope="row" rowSpan={addresses.length} aria-hidden="true">
                    Additional Addresses
                  </th>
                )}
                <td>
                  <SubmissionAddressDisplay
                    address={a}
                    isPrimary={false}
                    addressIndex={i + 1}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <em>No addresses</em>
      )}

      <h3>Firearms Licences</h3>
      <Table size="sm">
        <tbody>
          <tr>
            <th scope="row" id="submission-field-certificate-declaration-label">
              Certificate declaration
            </th>
            <td aria-labelledby="submission-field-certificate-declaration-label">
              {joinData.certificateRefusal === null ? (
                <em>
                  I have never had an application for an FAC or SGC refused by
                  the police or had such a certificate revoked
                </em>
              ) : (
                <>
                  <p>
                    <em>
                      I <strong>have</strong> had an application for an FAC or
                      SGC refused by the police or had such a certificate
                      revoked for the following reason:
                    </em>
                  </p>
                  <p>{joinData.certificateRefusal}</p>
                </>
              )}
            </td>
          </tr>
          {[
            { certType: "FAC", cert: joinData.firearmCertificate },
            { certType: "SGC", cert: joinData.shotgunCertificate },
          ].map(({ certType, cert }, i) => (
            <tr key={i}>
              <th
                scope="row"
                id={`submission-field-${certType.toLowerCase()}-details`}
              >
                {certType} <span className="sr-only">details</span>
              </th>
              <td
                aria-labelledby={`submission-field-${certType.toLowerCase()}-details`}
              >
                {cert !== null ? (
                  <>
                    <SubmissionDataField
                      field={
                        <>
                          <span className="sr-only">{certType}</span>{" "}
                          Certificate Number
                        </>
                      }
                      data={cert.certificateNumber}
                      dataStyle={{ fontFamily: "monospace" }}
                      fieldId={`${certType.toLowerCase()}-certificate-number`}
                    />
                    <SubmissionDataField
                      field={
                        <>
                          <span className="sr-only">{certType}</span> Issuing
                          Force
                        </>
                      }
                      data={cert.issuingForce}
                      fieldId={`${certType.toLowerCase()}-issuing-force`}
                    />
                    <SubmissionDataField
                      field={
                        <>
                          <span className="sr-only">{certType}</span> Expiry
                          Date
                        </>
                      }
                      data={moment(cert.expiryDate).format("LL")}
                      fieldId={`${certType.toLowerCase()}-expiry-date`}
                    />
                  </>
                ) : (
                  <em>No {certType}</em>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h3 id="submission-field-health-declaration">Health Declaration</h3>
      {joinData.medicalConditions === null ? (
        <p aria-labelledby="submission-field-health-declaration">
          <em>
            I have never been diagnosed with or treated for a mental or physical
            condition which might affect my safe possession of a firearm or
            shotgun
          </em>
        </p>
      ) : (
        <div aria-labelledby="submission-field-health-declaration">
          <p>
            <em>
              I <strong>have</strong> been diagnosed with or treated for a
              mental or physical condition which might affect my safe possession
              of a firearm or shotgun:
            </em>
          </p>
          <p>{joinData.medicalConditions}</p>
        </div>
      )}
    </>
  );
};

export default SubmissionStage;
